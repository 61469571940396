<template>
	<main class="main">
		<Slider></Slider>
		<section class="container" v-if="info">
			<div class="main__box">
				<Info :data="info"></Info>
			</div>
		</section>

		<section class="container">
			<div class="main__box">
				<Map
					:data="{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						groupedMarkers: getGroupedPoints,
						title: 'Пункты приема заявлений и выдачи ЕСК',
					}"
					:filters="filters"
				></Map>
			</div>
		</section>

		<section class="main__box">
			<div class="container">
				<h3 class="main__title">Последние новости</h3>
			</div>
			<SliderNews></SliderNews>
		</section>
	</main>
</template>

<script>
import Slider from '@/components/SliderMain.vue'
import Info from '@/components/Info.vue'
import Map from '@/components/Map.vue'
import SliderNews from '@/components/SliderNews.vue'

export default {
	name: 'HolderHome',

	components: {
		Slider,
		Info,
		Map,
		SliderNews,
	},

	computed: {
		info() {
			const blocks = this.$store.getters.BLOCKS
			return blocks[this.$options.name]
		},
		getGroupedPoints() {
			return this.$store.getters.POINTS_ORGANIZATION_GROUPED
		},
		filters() {
			return {
				cardType: false,
				list: false,
				type: 'points',
				store: {
					dispatch: 'SET_POINT_LIST_APPLIED_FILTERS',
					getters: 'POINT_LIST_APPLIED_FILTERS',
				},
				filters: [
					{
						name: 'Фильтр',
						data: [
							{
								type: 'checkbox',
								cardType: true,
								title: 'Тип карты',
								list: [
									{
										id: 'isBankCardIssuer',
										name: 'Банковская',
									},
									{
										id: 'isNonFinancialCardIssuer',
										name: 'Нефинансовая',
									},
								],
							},
							{
								type: 'checkbox',
								title: 'Пункт выдачи ЕСК',
								list: this.$store.getters.POINT_LIST_FILTERS,
							},
						],
					},
				],
			}
		},
	},
	mounted() {
		this.$store.dispatch('GET_POINTS_ORGANIZATION')
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
	},
}
</script>
